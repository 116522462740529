*{
    margin: 0;
    padding: 0;
    outline: none;
}
html{
    overflow:hidden;

}

body{
    overflow:hidden;
    background-color: rgba(0,140,188,1);

}

body.active{
    overflow:hidden;
    background-color: rgb(0, 14, 19);
}

.webgl{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
}

.container{
    width: 100%;
    height: 100%;
    position:absolute;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    
    background: rgb(135,199,208);
    background: -moz-linear-gradient(0deg, rgba(135,199,208,1) 0%, rgba(0,140,188,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(135,199,208,1) 0%, rgba(0,140,188,1) 100%);
    background: linear-gradient(0deg, rgba(135,199,208,1) 0%, rgba(0,140,188,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#87c7d0",endColorstr="#008cbc",GradientType=1);
}

@font-face{
    font-family: "integral";
    src: url(font/IntegralCF-Regular.otf);
}

a:visited {
    color: blue;
  }


 @media (hover:hover) and (pointer:fine){
    a:hover{
        color: brown;
        cursor: pointer;
    }
 }

.material-icons{
    cursor: pointer;
    font-size: 40px;
}
.material-icons:hover{
    color: #a52a2a;
}

.menu-text nav ul li{
    list-style-type: none;
    /* font-size:1em; */
    display: inline-block;
    padding: 5px 70px;
    position: relative;
    z-index: 1;
}

.menu-text{
    font-family:"integral",Helvetica,Arial,sans-serif;
    letter-spacing: 1px;
    font-size: 25px;
    color: antiquewhite;

    transition:all 0.1s linear;
    text-align: center;
    margin-top: 10%;

    position: relative;
    z-index: 1;
}

/* iphone x */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .menu-text nav ul li{
        font-size: 50px;
    }
    .material-icons{
        
        font-size: 100px;
    }
}


/* iphone 678plus */
@media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
    .menu-text nav ul li{
        font-size: 50px;
    }
}

/* iphone 678 */
@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) { 
    .menu-text nav ul li{
        font-size: 50px;
    }
}

/* iphone 5s */

@media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
    .menu-text nav ul li{
        font-size: 50px;
    }
}






.showreel-frame{
    /*     border: 1px solid red; */
    position: absolute;
    height: 100%;
    width: 100%;
    visibility: hidden;
    z-index: 3;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}


.showreel-frame.active{
    /*     border: 1px solid red; */
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.9);
    color: antiquewhite;
    z-index: 3;
    align-items: right;
}

.video-frame {
    /*     border: 1px solid rgb(44, 172, 87);  */
    position: absolute;
    width: 100%;
    height: 90%;
    transform: translate(0,0%);
}

.iframe-content{
    /*     border: 1px solid rgb(0, 17, 255);  */
    margin: 0 auto;
    width:100%;
    height: 100%;
  
}

#iframer{
    /*     border: 1px solid rgb(198, 52, 231);  */
    position:relative;
    height: 100%;
    width: 100%;
    border:0;
}


.contact{
    position: absolute;
    z-index: 2;
    visibility: hidden;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.contact.active{
    position: absolute;
    z-index: 2;
    visibility: visible;
    top: 45%;
    left: 50%;
    width: 80%;
    height: 40%;
    min-height:400px;
    transform: translate(-50%,-50%);

    background: rgba(255, 255, 255, 0.85);
    
    border-radius: 15px;

}
.contact-info{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 40px;
}

.fa {
    font-size: 40px;
    text-decoration: none;
  }




















